<template>
    <div ref="loadingContainer" class="uk-container uk-container-large">
        <template v-if="loading">
            <ol>
                <li class="loading-spinner">
                    <div data-uk-spinner="ratio: 4" />
                </li>
                <li class="loading-spinner">
                    {{ $t('loading.transport_ride') }}
                </li>
            </ol>
        </template>
        <template v-else>
            <a class="view-header">
                <div class="left">
                    <i class="fal fa-truck" />
                    <p>{{ ride.id | zeroPad }}</p>
                </div>
                <div class="middle">
                    <h4>{{ ride.vehicle ? ride.vehicle.name : '' }}
                        <span :class="statusColor">
                            ({{ loadingStatusString }})
                        </span>
                    </h4>
                    <p>{{ orderIdsString }}</p>
                </div>
                <div class="right" :class="statusColor">
                    <div class="pallet">
                        <p><strong>{{ ride.palletCount }}</strong></p>
                        <i class="fal fa-pallet-alt" />
                    </div>
                    <p><strong>{{ weight }}</strong> {{ $t('ton_short') }}</p>
                </div>
            </a>
            <ol class="list">
                <li>
                    <button
                        class="scroll"
                        v-if="showScrollDownButton && ! scrolledToBottom"
                        @click="scrollToBottom()"
                    >
                        <i class="far fa-chevron-down dynamic-title" />
                    </button>
                </li>
                <item
                    v-for="item in ride.items"
                    :ride="ride"
                    :item="item"
                    :expanded-item="expandedItem"
                    :action="action(item)"
                    :class="expandedItem === item.id ? 'uk-open' : ''"
                    :key="item.id"
                    :updating="updatingLine"
                    @loadAll="loadAll(item)"
                    @loadLine="updatePackingSlipLine(item, $event, 'load')"
                    @deloadLine="updatePackingSlipLine(item, $event, 'deload')"
                    @toggleItem="toggleExpandedItem(item)"
                    class="item-spacing"
                />
            </ol>
        </template>
    </div>
</template>

<style src="./LoadUnload.scss" lang="scss">

</style>

<script>
import { apiClient }      from '@/api';
import debounce           from 'debounce';
import { getSettings }    from "@/support/settings";
import Item               from './Item';
import roundTo            from '@/support/roundTo';
import { setCodeVersion } from '@/support/codeVersion';
import {
    ITEM_TYPE,
    RIDE_STATUS,
    SINGLE_RIDE_INCLUDES
} from '@/constants';

export default {
    name:       'LoadingView',

    components: { Item },

    data()
    {
        return {
            expandedItem:         null,
            loading:              true,
            ride:                 null,
            scroll:               debounce(this._scroll, 100),
            scrolledToBottom:     false,
            settings:             getSettings(),
            showScrollDownButton: false,
            updatingLine:         null,
        };
    },
    computed: {
        loadingStatusString() {
            if (this.statusCode === RIDE_STATUS.LOADED || this.statusCode === RIDE_STATUS.IN_TRANSIT) return this.$t('loaded').toLowerCase();

            if (this.statusCode === RIDE_STATUS.COMPLETED) return this.$t('finalized');

            return this.$t('load').toLowerCase();
        },
        orderIdsString() {
            let string = '';
            for (const item of this.ride.items) {
                if      (string !== '')    string += ' - ';
                if      (item.packingSlip) string += this.$options.filters.zeroPad(item.packingSlip.order);
                else if (item.order)       string += this.$options.filters.zeroPad(item.order.id);
                else if (item.type)        string += this.$options.filters.zeroPad(item.purchaseOrder);
                else                       throw new Error('Unknown item type');
            }
            return string;
        },
        statusCode() {
            return this.ride.status.code;
        },
        statusColor() {
            if (this.statusCode === RIDE_STATUS.LOADED || this.statusCode === RIDE_STATUS.IN_TRANSIT) return 'status-blue';

            if (this.statusCode === RIDE_STATUS.COMPLETED) return 'status-green';

            return 'status-yellow';
        },
        weight() {
            return roundTo(this.ride.weight / 1000, 2, false);
        }
    },
    async mounted () {
        window.addEventListener('scroll', this.scroll);

        try {
            // Since this page is directly accessible by the address bar, make sure we have an up-to-date status of the code version
            const response = await apiClient.get('auth/info', { params: { include: 'codeVersion' }});
            setCodeVersion(response.data.codeVersion);
        } catch (e) {
            console.error(e); // eslint-disable-line no-console
        }
    },
    async created() {
        try {
            const requestConfig = {
                params: {
                    id:      this.$route.params.rideId,
                    include: SINGLE_RIDE_INCLUDES,
                },
            };
            const result = await apiClient.get(
                'transport/transport_rides',
                requestConfig
            );
            this.ride = result.data.data[0];
        } catch (e) {
            console.error(e); //eslint-disable-line no-console
            throw e;
        } finally {
            this.loading = false;
        }
    },
    methods: {
        action(item) {
            return (item.type && item.type === ITEM_TYPE.PURCHASE_RECEIPT)
                ? 'view'
                : 'load'
            ;
        },
        async loadAll(item) {
            try {
                this.updatingLine = 'all';
                const response    = await apiClient.put(`transport/items/${item.id}/loadAll`);
                const itemIdx     = this.ride.items.findIndex((existingItem) => {
                    return existingItem.id === item.id;
                });
                this.ride.items.splice(itemIdx, 1, response.data.data);
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
                throw e;
            } finally {
                this.updatingLine = null;
            }
            await this.updateRideStatus();
        },
        async updatePackingSlipLine(item, line, action) {
            if (
                line.loaded && action === 'load'
                || ! line.loaded && action === 'deload'
                || (this.ride.status.code >= RIDE_STATUS.LOADED && ! this.settings.less_restrictions_for_starting_delivery)
            ) {
                return;
            }
            try {
                this.updatingLine = line.id;

                const loaded = action === 'load';

                const response = await apiClient.put(
                    `transport/packing_slips/${line.packingSlip}/lines/${line.id}`,
                    { loaded }
                );
                await this.updateLineData(item, response.data.data);
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
                throw e;
            } finally {
                this.updatingLine = null;
            }
        },
        async updateLineData(item, lineData) {
            const lines = item.packingSlip.lines;
            for (let i = 0; i < lines.length; i++) {
                if (lines[i].id === lineData.id) {
                    lines.splice(i, 1, lineData);
                    break;
                }
            }
            await this.updateRideStatus();
        },
        async updateRideStatus() {
            if (this.ride.status.code >= RIDE_STATUS.LOADED) return;

            let lineCount   = 0;
            let loadedCount = 0;
            for (let item of this.ride.items) {
                if (item.type && item.type !== ITEM_TYPE.PACKING_SLIP) continue;
                lineCount += item.packingSlip.lines.length;
                for (let line of item.packingSlip.lines) {
                    if (line.loaded) loadedCount++;
                }
            }
            let status = this.ride.status.code;
            if (loadedCount === lineCount) {
                status = RIDE_STATUS.LOADED;
            } else if (loadedCount > 0) {
                status = RIDE_STATUS.BEING_PREPARED;
            }
            if (status !== this.ride.status.code) {
                try {
                    const response = await apiClient.put(
                        `transport/transport_rides/${this.ride.id}?include=${SINGLE_RIDE_INCLUDES}`,
                        { status },
                    );

                    this.ride = response.data.data;
                } catch (e) {
                    console.error(e); //eslint-disable-line no-console
                    throw e;
                } finally {
                    // this.loading = false; TODO do we need to show the user that the status is being updated??
                }
            }
        },
        async toggleExpandedItem(item) {
            if (item.type && item.type !== ITEM_TYPE.PACKING_SLIP && item.type !== ITEM_TYPE.PURCHASE_RECEIPT) return;

            this.expandedItem = this.expandedItem !== item.id
                ? item.id
                : null
            ;
            await this.$nextTick();
            this.showScrollDownButton = (this.$refs.loadingContainer.offsetTop + this.$refs.loadingContainer.scrollHeight) > window.innerHeight;
        },
        scrollToBottom() {
            window.scrollTo(0, window.scrollY + 150);
        },
        _scroll() {
            this.scrolledToBottom = document.documentElement.offsetHeight - (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight) <= 50;
        },
    },
};
</script>
